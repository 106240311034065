<template>
<div :style="{'--themecolor':themecolors}">
    <div class="deticleBox" id="scroll">
        <div style="min-height:95%;">
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading"/>
            <van-empty :image="require('@/assets/null.svg')" v-else-if="news_info == {}"/>
            <div class="deticleCon" v-else>
                <div class="top">
                    <p>{{news_info.new_title}}</p>
                    <div class="flex" style="margin-left:10px;color:#969799;"><span>{{news_info.author}} {{news_info.created_at}}</span><div class="flex"><van-icon name="eye-o" style="font-size:15px;"/>&nbsp;{{news_info.views_count}}</div></div>
                </div>
                <div class="content" v-html="news_info.text"></div>
                <div class="bottom flex">
                    <p :style="(news_list.length>0)&&(news_list[0].id == current_id) ? 'color:#999;' : ''" @click="news_list[0].id != current_id && prev()">上一篇</p>
                    <p :style="(news_list.length>0)&&(news_list[news_list.length-1].id == current_id) ? 'color:#999;' : ''" @click="news_list[news_list.length-1].id != current_id && next()">下一篇</p>
                </div>
                <div class="Popup" v-if="ispopup">
                    <div class="popupcon">
                        <div class="popupTitle">
                            <span>嘉宾简介</span>
                            <span @click="closePopup()">X</span>
                        </div>
                        <div class="popupline"></div>
                        <div class="Popupcontent">
                            博士，麻醉教授，博士生导师 河南医科大学麻醉学院院长，河南医科大学附属
                            医院副院长，从事慢性疼痛脑机制 以经方医学流派的研究为主攻方向，其中尤以经
                            方的方证与药证为研究重点 。现致力于经方的普及推广工作，主持全球最大的公益性经方学术网站：“经方医学论坛
                            农工民主党江苏省委副主委、农工民主党南京市委主委、南京中医药大学基础医学院名誉院长、教授、博士生导师。
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <Support></Support>
    </div>
    <sideBar></sideBar>
</div>
</template>
<script>
import { Popup } from 'vant';
import sideBar from '@/components/sidebar';
import Support from '@/components/support';
import merge from 'webpack-merge';
import { mapState } from 'vuex'
export default {
    components:{
        sideBar,
        Support
    },
    data(){
        return{
            ispopup:false,
            news_info:{},
            Loading:true,
            news_list:[],
            current_id:null,
        }
    },
    created(){
        this.params = this.$route.query;
        this.current_id = this.params.id;
    },
    mounted(){
        this.show_news_info();
        if(this.params.module_id){
            this.news_information_list();
        }else if(this.params.site_id){
            this.showroom_news_list()
        }  
        
    },
    computed: {
        ...mapState(['themecolors'])
    },
    methods:{
      introduce(){
          this.ispopup = true
      },
      closePopup(){
          this.ispopup = false
      },
      prev(){
          for(let i = 0; i <this.news_list.length; i++){
              if(this.news_list[i].id == this.current_id){
                  this.current_id = this.news_list[i-1].id;
                  this.$router.push({
                    query:merge(this.$route.query,{'id':this.current_id})
                  })
                  this.show_news_info();
                  return
              }
          }
      },
      next(){
          for(let i = 0; i <this.news_list.length; i++){
              if(this.news_list[i].id == this.current_id){
                  this.current_id = this.news_list[i+1].id;
                  this.$router.push({
                    query:merge(this.$route.query,{'id':this.current_id})
                  })
                  this.show_news_info();
                  return
              }
          }
      },
      // 展厅展示新闻资讯分类列表
        showroom_news_list(){
            this.$store.dispatch("showroom_news_list",{
                site_id:this.params.site_id,
                show_grouping:this.params.show_grouping
            }).then(res=>{
                if(res.data.code === 200){
                    document.title = res.data.data.data.module_title
                    this.news_list = res.data.data.data.module_content;
                }
            })
        },
      news_information_list(){
        this.$store.dispatch("news_information_list",{
            site_id:this.params.site_id,
            module_id:this.params.module_id,
            page:1,
            page_num:20,
        }).then(res=>{
            if(res.data.code == 200){
                this.news_list = res.data.data.module_content.data;
                document.title = res.data.data.module_title
            }
        })
      },
      show_news_info(){
          this.Loading = true;
          this.$store.dispatch("show_news_info",{
              id:this.current_id,
          }).then(res=>{
              this.Loading = false;
              if(res.data.code == 200){
                  this.news_info = res.data.data;
              }
          })
      },
    }
}
</script>
<style lang="less" scoped>
@Color:var(--themecolor);
/deep/.van-loading{
    position: absolute;
    top: -10%;
    bottom: 0;
    left:50%;
    transform:translateX(-50%);
}
/deep/.van-loading {
    top: 40%;
}
.flex{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.bottom{
    font-size: .12rem;
    margin: .2rem .1rem;
    cursor: pointer;
    color: @Color;
}
.deticleBox::-webkit-scrollbar{
    display: none;
}
.deticleBox{
    width: 100%;
    height: 100%;
    background: #F7F8FA;
    overflow-y: scroll;
    .deticleCon{
        width: 100%;
        height: 100%;
        margin:0 auto;
        padding: 0 .15rem;
        overflow-x: hidden;
        .top{
            font-size: .14rem;
            padding-top: .1rem;
            padding-bottom: .1rem;
            p{
                color: #323233;
                font-size: .18rem;
                overflow: hidden;
                // display: -webkit-box;//将元素设为盒子伸缩模型显示
                // -webkit-box-orient: vertical;//伸缩方向设为垂直方向
                // -webkit-line-clamp: 2;//超出3行隐藏，并显示省略号
                // height: 45px;
                // line-height: .4rem;
                word-break: break-all;
                margin-bottom: 0.1rem;
            }
            span{
                color: #969799;
                font-size: .14rem;
            }
        }
        .content{
            font-size: .14rem;
            word-break: break-all;
            p{
                line-height: .24rem;
                color: #646566;
                word-break:break-all;
            }
        }
        .Popup{
            width: 94%;
            height: 4rem;
            background: #313131;
            position: absolute;
            top:1.5rem;
            border-radius: .03rem;
            .popupcon{
                width: 94%;
                margin:0 auto;
                padding: .05rem;
            }
            .popupTitle{
                display: flex;
                justify-content: space-between;
                height: .4rem;
                line-height: .4rem;
                span{
                    color: #ffffff;
                    font-size: .16rem;
                }
            }
            .popupline{
                width: 100%;
                border:0.01rem solid #ffffff;
            }
            .Popupcontent::-webkit-scrollbar{
                display: none;
            }
            .Popupcontent{
                width: 100%;
                height: 3.35rem;
                overflow: scroll;
                font-size: .16rem;
                color: #D8D8D8;
                margin-top: .14rem;
            }
        }
    }
}
</style>